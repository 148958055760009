import styled from 'styled-components';

import { COLORS } from '@constants/colors';
import { SCREENS } from '@constants/screens';

import {
    AuthButtonContainer,
    AuthForm,
    AuthFormError,
    AuthFormSuccess,
    AuthInput,
    InputContainer,
    EyeContainer as EyeContainerPassword,
} from '../styled';

export const LoginTitle = styled.span`
    font-style: normal;
    font-weight: 800;
    font-size: 32px;
    line-height: 48px;
    margin-bottom: 20px;
    text-align: center;

    ${SCREENS.tabletAndLower} {
        font-size: 20px;
    }
`;

export const LoginFormError = styled(AuthFormError)``;
export const ResetPasswordFormSuccess = styled(AuthFormSuccess)``;
export const SetupPasswordFormSuccess = styled(AuthFormSuccess)``;

export const LoginForm = styled(AuthForm)``;

export const UsernameContainer = styled(InputContainer)``;

export const PasswordContainer = styled(InputContainer)`
    display: flex;
    align-items: center;
`;

export const Input = styled(AuthInput)``;

export const EyeContainer = styled(EyeContainerPassword)``;

export const LoginButtonContainer = styled(AuthButtonContainer)``;

export const LoginButtonTitle = styled.span`
    color: ${COLORS.white};
`;

export const ResetPassword = styled.a`
    color: ${COLORS.pink};
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 150%;
    margin-top: 18px;
    user-select: none;
    text-align: center;
    cursor: pointer;

    ${SCREENS.tabletAndLower} {
        font-size: 10px;
        font-weight: normal;
    }
`;
