import React, { useContext, useState, useRef, useEffect } from 'react';

import { useRouter } from 'next/router';
import Link from 'next/link';

import { ContentStudioApiContext } from '@santeacademie/contentstudio-api-client';

import { t } from '@locales/translation';
import { COLORS } from '@constants/colors';
import { EyeWithBackground } from '@assets/svg/Eye';
import { useBodyFlex } from '@hooks/useBodyFlex';
import PrimaryButton from '@components/Button/PrimaryButton';
import { CookieStoreContext } from '@stores/CookieStoreContext';

import AuthLayout, { PasswordInputEnum } from '../AuthLayout';
import * as S from './styled';

const Login = (): JSX.Element => {
    const router = useRouter();
    const { email: queryEmail, resetpasswordsuccess, setuppasswordsuccess, redirect: queryRedirect } = router.query;

    const [passwordInputType, setPasswordInputType] = useState<PasswordInputEnum>(PasswordInputEnum.PASSWORD);
    const [email, setEmail] = useState<string | undefined>('');
    const [password, setPassword] = useState<string | undefined>('');
    const [error, setError] = useState<string>('');
    const [isFetchingLogin, setIsFetchingLogin] = useState<boolean>(false);

    const emailInputRef = useRef<HTMLInputElement>(null);
    const passwordInputRef = useRef<HTMLInputElement>(null);

    const { login, getLearner } = useContext(ContentStudioApiContext);
    const { addLearnerIdentifierCookie } = useContext(CookieStoreContext);

    let redirect = '/';

    if (queryRedirect && !Array.isArray(queryRedirect)) {
        redirect = queryRedirect.trim();
    }

    useBodyFlex();

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();

        if (isFetchingLogin) return;
        setIsFetchingLogin(true);

        setError('');
        try {
            if (!email || !password) {
                setIsFetchingLogin(false);
                return;
            }
            const loginResponse = await login({ username: email.toLowerCase().trim(), password });

            const learner = await getLearner({});

            addLearnerIdentifierCookie(learner, loginResponse.expires_in);

            router.push(redirect);
        } catch (error: any) {
            if (error['getCode']) {
                if (error?.getCode() === 400) {
                    setError(t('page.auth.login.credentialError'));
                } else {
                    setError(t('page.auth.login.genericError'));
                }
            } else {
                console.error(error);
                setError(t('page.auth.login.genericError'));
            }
        } finally {
            setIsFetchingLogin(false);
        }
    };

    const onClickDisplayPassword = () => {
        if (passwordInputType === PasswordInputEnum.PASSWORD) {
            setPasswordInputType(PasswordInputEnum.TEXT);
        } else {
            setPasswordInputType(PasswordInputEnum.PASSWORD);
        }
    };

    useEffect(() => {
        if (!Array.isArray(queryEmail) && queryEmail) {
            setEmail(queryEmail);

            if (passwordInputRef && passwordInputRef.current) {
                passwordInputRef.current.focus();
            }
        }
    }, [queryEmail]);

    useEffect(() => {
        if (emailInputRef && emailInputRef.current) {
            emailInputRef.current.focus();
        }
    }, [emailInputRef]);

    return (
        <AuthLayout>
            <S.LoginTitle>{t('page.auth.login.title')}</S.LoginTitle>
            <S.LoginForm onSubmit={handleSubmit}>
                {error ? <S.LoginFormError>{error}</S.LoginFormError> : ''}
                {resetpasswordsuccess && resetpasswordsuccess === 'true' && (
                    <S.ResetPasswordFormSuccess>
                        {t('page.auth.resetPasswordConfirmation.success')}
                    </S.ResetPasswordFormSuccess>
                )}
                {setuppasswordsuccess && setuppasswordsuccess === 'true' && (
                    <S.SetupPasswordFormSuccess>{t('page.auth.setupPassword.success')}</S.SetupPasswordFormSuccess>
                )}

                <S.UsernameContainer>
                    <S.Input
                        placeholder={t('page.auth.login.username')}
                        type="text"
                        value={email || ''}
                        autoComplete="username"
                        ref={emailInputRef}
                        onFocus={() => {
                            setError('');
                        }}
                        onChange={(event) => {
                            setEmail(event.target.value.toLowerCase());
                        }}
                        data-testid="email-input"
                    />
                </S.UsernameContainer>
                <S.PasswordContainer>
                    <S.Input
                        placeholder={t('page.auth.login.password')}
                        type={passwordInputType}
                        autoComplete="current-password"
                        onChange={(event) => {
                            setPassword(event.target.value);
                        }}
                        ref={passwordInputRef}
                        onFocus={() => {
                            setError('');
                        }}
                        value={password || ''}
                        data-testid="password-input"
                    />
                    <S.EyeContainer onClick={onClickDisplayPassword}>
                        <EyeWithBackground fill={COLORS.pink} />
                    </S.EyeContainer>
                </S.PasswordContainer>
                <S.LoginButtonContainer>
                    <PrimaryButton
                        type="submit"
                        style={{ flex: 1 }}
                        data-testid="submit-button"
                    >
                        <S.LoginButtonTitle>
                            {isFetchingLogin
                                ? t('page.auth.login.loginButtonTitleLoading')
                                : t('page.auth.login.loginButtonTitle')}
                        </S.LoginButtonTitle>
                    </PrimaryButton>
                </S.LoginButtonContainer>
            </S.LoginForm>
            <Link href="/reset-password">
                <S.ResetPassword
                >
                    {t('page.auth.login.resetPassword')}
                </S.ResetPassword>
            </Link>
        </AuthLayout>
    );
};

export default Login;
