import { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';

import { CookieStoreContext } from '@stores/CookieStoreContext';

const useSessionRedirect = () => {
    const router = useRouter();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { learnerIdentifierCookie } = useContext(CookieStoreContext);

    useEffect(() => {
        if (learnerIdentifierCookie && typeof learnerIdentifierCookie === 'object') {
            router.push('/');
        } else {
            setIsLoading(false);
        }
    }, []);

    return [isLoading];
};

export default useSessionRedirect;
